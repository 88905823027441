
.nav-tabs li{
    padding-bottom: 6px;
    margin-bottom: 6px;
    border-top: 3px solid transparent;
}
.nav-tabs li a{
    padding: 6px;
    margin-top: 3px;
    
}
.nav-tabs li a:read-only{
cursor: pointer;

}

.nav-tabs li.active{
 border-top-color: #5C01EB;

}
.tab-content{
    margin-block-start: 0px;
    padding-inline-start: 0px;
}
