@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

.content-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;

}

h1{
    font-family: 'Bebas Neue', cursive;
}
p{
    font-family: 'Open Sans', sans-serif;
}
button:focus{
    outline: none;
}